html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  /* stylelint-disable */
  font-family: monospace, monospace;
  /* stylelint-enable */
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

button,
input,
select,
textarea {
  font: inherit; }

optgroup {
  font-weight: bold; }

button,
input,
select {
  overflow: visible; }

button,
input,
select,
textarea {
  margin: 0; }

button,
select {
  text-transform: none; }

[type="button"],
[type="reset"],
[type="submit"],
button {
  cursor: pointer; }

[disabled] {
  cursor: default; }

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  appearance: button; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  appearance: field; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

.swatch-attribute-label {
  font-weight: bold;
  position: relative; }

.swatch-attribute-label.required {
  padding-right: 10px; }

.swatch-attribute-label[data-required="1"]:after {
  content: '*';
  color: red;
  position: absolute;
  right: -11px;
  top: -2px;
  font-weight: bold;
  font-size: 1em; }

.swatch-attribute-selected-option {
  color: #646464;
  padding-left: 17px; }

.swatch-attribute-options {
  margin-top: 10px; }

.swatch-option {
  padding: 1px 2px;
  min-width: 30px;
  max-width: 90px;
  height: 20px;
  float: left;
  margin: 0 10px 5px 0;
  text-align: center;
  cursor: pointer;
  position: relative;
  border: 1px solid #dadada;
  overflow: hidden;
  text-overflow: ellipsis; }

.swatch-option.text {
  background: #f0f0f0;
  color: #686868;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  padding: 4px 8px;
  min-width: 22px;
  margin-right: 7px; }

.swatch-option.selected {
  outline: 2px solid #ff5501;
  border: 1px solid #fff;
  color: #333; }

.swatch-option.text.selected {
  background-color: #fff !important; }

.clearfix:after {
  content: '';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

.swatch-attribute.size .swatch-option,
.swatch-attribute.manufacturer .swatch-option {
  background: #f0f0f0;
  color: #949494; }

.swatch-attribute.size .swatch-option.selected,
.swatch-attribute.manufacturer .swatch-option.selected {
  color: black;
  background: #fff;
  border: 1px solid #fff; }

.swatch-option:not(.disabled):hover {
  outline: 1px solid #999;
  border: 1px solid #fff;
  color: #333; }

.swatch-option.image:not(.disabled):hover,
.swatch-option.color:not(.disabled):hover {
  outline: 2px solid #ee0000;
  border: 1px solid #fff; }

.swatch-option.disabled {
  cursor: default; }

.swatch-option.disabled:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, white 43%, white 46%, #ff5216 47%, #ff5216 53%, white 54%, white 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%); }

.swatch-option-tooltip {
  max-width: 140px;
  max-height: 100%;
  min-height: 20px;
  min-width: 20px;
  position: absolute;
  padding: 5px;
  background: #fff;
  color: #949494;
  border: 1px solid #adadad;
  display: none;
  z-index: 999;
  text-align: center; }

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
  left: 40%;
  position: absolute;
  bottom: 0;
  height: 8px; }

.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
  content: '';
  position: relative;
  top: 1px;
  left: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7.5px 0 7.5px;
  border-color: #fff transparent transparent transparent;
  font-size: 1px; }

.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before {
  content: '';
  position: relative;
  top: 2px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8.5px 0 8.5px;
  border-color: #adadad transparent transparent transparent;
  font-size: 1px; }

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
  display: block;
  height: 130px;
  width: 130px;
  margin: 0 auto; }

.swatch-option-tooltip .title {
  max-width: 140px;
  min-height: 20px;
  max-height: 200px;
  color: #282828;
  text-align: center;
  display: block;
  overflow: hidden; }

.swatch-opt {
  margin: 20px 0; }

.swatch-more {
  display: inline-block;
  margin: 2px 0;
  text-decoration: none !important;
  position: static;
  z-index: 1; }

.swatch-option-link-layered {
  position: relative;
  margin: 0 !important;
  padding: 0 !important; }

.swatch-option-link-layered:focus > div {
  box-shadow: 0 0 3px 1px #68a8e0; }

.swatch-option-tooltip-layered {
  width: 140px;
  position: absolute;
  background: #fff;
  color: #949494;
  border: 1px solid #adadad;
  display: none;
  z-index: 999;
  left: -47px; }

.swatch-visual-tooltip-layered {
  height: 160px;
  top: -170px; }

.swatch-textual-tooltip-layered {
  height: 30px;
  top: -40px; }

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
  display: block; }

.swatch-attribute-options .swatch-option-tooltip-layered .title {
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: -5px;
  left: 0;
  color: #282828;
  text-align: center;
  margin-bottom: 10px; }

.swatch-option-disabled:after {
  content: '';
  position: absolute;
  width: 42px;
  height: 2px;
  background: red;
  transform: rotate(-30deg);
  z-index: 995;
  left: -4px;
  top: 10px; }

.swatch-option-disabled {
  outline: none !important;
  cursor: default;
  border: 0; }

.swatch-opt-listing {
  margin-bottom: 10px; }

.swatch-option-loading {
  content: url(../Magento_Swatches/images/loader-2.gif); }

.swatch-input {
  left: -1000px;
  position: absolute;
  visibility: hidden; }

.page-print .logo {
  float: none;
  display: block;
  text-align: left; }

@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    -webkit-filter: none !important;
    filter: none !important; }
  a,
  a:visited {
    text-decoration: underline !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  .table-wrapper table {
    width: 100%;
    table-layout: fixed; }
    .table-wrapper table td {
      width: auto; }
    .table-wrapper table > tfoot > tr:first-child {
      border-top: 1px solid #999; }
  .box,
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 1cm; }
  .block-content,
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  .block-content {
    page-break-before: avoid; }
  .block-title,
  h2,
  h3 {
    page-break-after: avoid; }
  .nav-toggle {
    display: none !important; }
  .sidebar,
  .nav-sections,
  .header.content > *[class],
  .panel.wrapper > *[class],
  .footer.content > *[class] {
    display: none; }
  .logo,
  .footer .copyright {
    display: block !important;
    margin: 10px 0; }
  .order-details-items .order-items .order-gift-message:not(.expanded-content) {
    visibility: visible;
    height: auto; }
  .column.main {
    width: 100% !important;
    float: none !important; }
  .breadcrumbs {
    margin: 0 auto;
    text-align: right; }
  .footer.content {
    padding: 0; } }
